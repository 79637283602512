import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  addEndScreen,
  deleteEndScreen,
  setEndScreenAsDefault,
} from 'actions/survey';
import { campaignDraftLoadingSelector } from 'selectors/campaignLoading';
import { endScreensSelectorFactory } from 'selectors/survey';

import ManagementList from 'components/ui/ManagementList';
import ManagementListRowFactory from 'components/ui/ManagementList/ManagementListRowFactory';
import NavigationSectionHeader from 'components/ui/SectionHeader';
import LightText, { LimitedTextCell } from 'components/ui/Text';
import { TabButton } from 'components/ui/button/TabButton';
import {
  ConditionedLabel,
  DefaultLabel,
  NoConditionLabel,
} from 'components/ui/inputs/Label';

import * as svars from 'assets/style/variables';

const labelStyles = {
  margin: `${svars.spaceSmaller} 0 ${svars.spaceXSmall} 0`,
};

const CounterText = styled.span`
  font-style: italic;
  margin-right: ${svars.spaceNormal};
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
`;

const MAX_END_SCREENS = 10;

function NameAndStatusCell({ value, defaultScreen, noConditions }) {
  return (
    <span
      style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
    >
      <LimitedTextCell>
        {value?.trim() || (
          <LightText>
            <Trans id="(untitled)" />
          </LightText>
        )}
      </LimitedTextCell>
      <span style={labelStyles}>
        {(defaultScreen && <DefaultLabel small />) ||
          (noConditions && <NoConditionLabel small />) || (
            <ConditionedLabel small />
          )}
      </span>
    </span>
  );
}

NameAndStatusCell.propTypes = {
  value: PropTypes.string,
  defaultScreen: PropTypes.bool.isRequired,
  noConditions: PropTypes.bool.isRequired,
};

NameAndStatusCell.defaultProps = {
  value: null,
};

const DEFAULT_SORTED = { key: 'id', desc: false };
function EndScreenList({ campaignId, endScreenIndex, onSelectEndScreen }) {
  const dispatch = useDispatch();
  const endScreens = useSelector(endScreensSelectorFactory(campaignId, true));
  const campaignConfigurationIsLoading = useSelector(
    campaignDraftLoadingSelector
  );

  const onDeleteEndScreen = useCallback(
    (index) => () => {
      dispatch(deleteEndScreen(campaignId, index));
      if (endScreenIndex === index) {
        onSelectEndScreen(null);
      }
    },
    [dispatch, campaignId, endScreenIndex, onSelectEndScreen]
  );

  const onSetEndScreenAsDefault = useCallback(
    (index) => () => {
      if (endScreens[index]?.default) {
        return;
      }
      dispatch(setEndScreenAsDefault(campaignId, index));
    },
    [dispatch, campaignId, endScreens]
  );

  const onAddEndScreen = useCallback(() => {
    if (endScreens.length < MAX_END_SCREENS) {
      dispatch(addEndScreen(campaignId));
    }
  }, [dispatch, campaignId, endScreens]);

  const renderEndScreenRow = useMemo(
    () =>
      ManagementListRowFactory(
        onDeleteEndScreen,
        [],
        endScreens.length > 1,
        null,
        null,
        null,
        (row) => !row
      ),
    [onDeleteEndScreen, onSetEndScreenAsDefault]
  );

  const endScreenFields = useMemo(
    () => [
      {
        id: 'title',
        key: 'title',
        accessor: (it) => it.label,
        Cell: NameAndStatusCell,
        width: 100,
        label: null,
        getCellProps: (localItem) => ({
          defaultScreen: localItem?.default,
          noConditions: (localItem?.conditions?.length || 0) === 0,
        }),
      },
    ],
    [onDeleteEndScreen, onSetEndScreenAsDefault]
  );

  const formattedEndScreens = useMemo(
    () =>
      endScreens.map((endScreen, index) => ({
        ...endScreen,
        id: index,
        menuItems: [
          {
            content: t({ id: 'set-as-default' }),
            icon: 'star',
            onClick: onSetEndScreenAsDefault(index),
            disabled: endScreen?.default,
          },
          {
            content: t({ id: 'delete' }),
            icon: 'delete',
            iconColor: 'red',
            onClick: onDeleteEndScreen(index),
            disabled: endScreen?.default,
          },
        ],
      })),
    [endScreens, onDeleteEndScreen, onSetEndScreenAsDefault]
  );

  const onEndScreenEdition = useCallback(
    ({ id: index }) => {
      onSelectEndScreen(index);
    },
    [onSelectEndScreen]
  );

  return (
    <>
      <NavigationSectionHeader
        title={t({ id: 'survey-ending-page' })}
        navigationSectionTestId="bo-campaign-ending-page-button"
        action={
          <ActionContainer>
            <CounterText>
              {endScreens.length} / {MAX_END_SCREENS}
            </CounterText>
            <TabButton
              fitted="true"
              onClick={onAddEndScreen}
              disabled={
                campaignConfigurationIsLoading ||
                endScreens.length >= MAX_END_SCREENS
              }
            >
              <Icon
                data-testid="bo-campaign-add-end-screen"
                name="add"
                style={{ margin: 0 }}
              />
            </TabButton>
          </ActionContainer>
        }
      />
      <ManagementList
        compact
        items={formattedEndScreens}
        rowFields={endScreenFields}
        onRowClick={onEndScreenEdition}
        renderItemRow={renderEndScreenRow}
        withTextFilter={false}
        isRowActive={(row) => row.id === endScreenIndex}
        defaultSorted={DEFAULT_SORTED}
        emptyListHeader={<Trans id="no-end-screens" />}
        actions={null}
        style={{ minHeight: '6rem' }}
      />
    </>
  );
}

EndScreenList.propTypes = {
  campaignId: PropTypes.string.isRequired,
  endScreenIndex: PropTypes.number,
  onSelectEndScreen: PropTypes.func.isRequired,
};

EndScreenList.defaultProps = {
  endScreenIndex: null,
};

export default EndScreenList;
