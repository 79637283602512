import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { updateQuestionSetting } from 'actions/survey';

import Header from 'components/ui/Header';
import { CompactMessage } from 'components/ui/Text';
import InlineFormField from 'components/ui/form/InlineFormField';
import DaySelect from 'components/ui/inputs/DaySelect';

import commonPropTypes from 'utils/commonPropTypes';
import { useDate } from 'utils/hooks';

import * as svars from 'assets/style/variables';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: ${svars.spaceMedium};
`;

const updateQuestionField =
  (dispatch, campaignId, questionId, field) => (value) =>
    dispatch(updateQuestionSetting(campaignId, questionId, field, value));

function DateQuestionSettings({ question, campaignId, questionId }) {
  const dispatch = useDispatch();

  const onMinDateChange = useCallback(
    updateQuestionField(dispatch, campaignId, questionId, 'min_date'),
    [dispatch, questionId, campaignId]
  );
  const onMaxDateChange = useCallback(
    updateQuestionField(dispatch, campaignId, questionId, 'max_date'),
    [dispatch, questionId, campaignId]
  );
  const minDate = useDate(question.min_date);
  const maxDate = useDate(question.max_date);
  const datesError = maxDate && minDate && maxDate < minDate;
  return (
    <Container data-testid="bo-date-question-container">
      <Header>
        <Trans id="date-question-min-max-settings" />
        <Header.Subheader>
          <Trans id="date-question-min-max-settings-description" />
        </Header.Subheader>
      </Header>
      <InlineFormField
        labelId="date-minimum"
        input={
          <DaySelect
            fromDate={null}
            toDate={null}
            selected={minDate}
            onSelect={onMinDateChange}
          />
        }
      />
      <InlineFormField
        labelId="date-maximum"
        input={
          <DaySelect
            fromDate={null}
            toDate={null}
            error={datesError}
            selected={maxDate}
            onSelect={onMaxDateChange}
          />
        }
      />
      {datesError && (
        <CompactMessage
          error
          header={t({ id: 'invalid-period' })}
          content={t({ id: 'facet-create.select-higher-minimum-date' })}
        />
      )}
    </Container>
  );
}

DateQuestionSettings.propTypes = {
  question: commonPropTypes.question,
  campaignId: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
};
DateQuestionSettings.defaultProps = {
  question: {},
};

export default DateQuestionSettings;
